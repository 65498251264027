export default function IconMW() {
  return (
    <svg
      className="icon icon-mw"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 201 201"
    >
      <g
        id="streamlinehq-common-file-text-shield-files-folders-20"
        transform="translate(0.5 0.5)"
      >
        <path
          id="Pfad_2805"
          data-name="Pfad 2805"
          d="M118.13,51.33a68.3,68.3,0,0,1-53.44,66.8,68.305,68.305,0,0,1-53.44-66.8V24.61A13.36,13.36,0,0,1,24.61,11.25h80.16a13.36,13.36,0,0,1,13.36,13.36Z"
          transform="translate(81.87 81.87)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Pfad_2808"
          data-name="Pfad 2808"
          d="M3.75,6.75H97.27"
          transform="translate(22.97 46.583)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Pfad_2809"
          data-name="Pfad 2809"
          d="M3.75,11.25h33.4"
          transform="translate(22.97 82.083)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Pfad_2810"
          data-name="Pfad 2810"
          d="M3.75,15.75h33.4"
          transform="translate(22.97 117.583)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Pfad_2811"
          data-name="Pfad 2811"
          d="M60.87,174.03H14.11A13.345,13.345,0,0,1,.75,160.7V14.079A13.345,13.345,0,0,1,14.11.75h94.669a13.374,13.374,0,0,1,9.439,3.9L143.8,30.172a13.314,13.314,0,0,1,3.91,9.419v21.14"
          transform="translate(-0.75 -0.75)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
