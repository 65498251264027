import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BlockchainNotarisierung from "./apps/BlockchainNotarisierung/BlockchainNotarisierung";
import {
  DIALOG_STEP_CREATE,
  DIALOG_STEP_HOME,
  DIALOG_STEP_VERIFY,
} from "./apps/BlockchainNotarisierung/constants/constants";


declare global {
  interface Window {
    WK: any;
    dataLayer: any;
    initNavPanels: () => void;
    initOverflowNav: () => void;
    mobileNav: () => void;
  }
}
var WK: any = window.WK || {};
WK.react = WK.react || {};

WK.react.blockchainNotarisierung = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <Suspense fallback={<h1>Loading...</h1>}>
          <Routes>
            <Route
              path="/"
              element={
                <BlockchainNotarisierung currentView={DIALOG_STEP_HOME} />
              }
            />
            <Route
              path="/create"
              element={
                <BlockchainNotarisierung currentView={DIALOG_STEP_CREATE} />
              }
            />
            <Route
              path="/verify"
              element={
                <BlockchainNotarisierung currentView={DIALOG_STEP_VERIFY} />
              }
            />
          </Routes>
        </Suspense>
      </Router>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

WK.react.blockchainNotarisierung();
