import { useState } from "react";
import {
  MeinWKONotarisierungJsonResponseType,
  NotarisierungJsonRequestType,
} from "../../../api";
import { blockchainService } from "../service/BlockchainService";
import { FileUpload } from "primereact/fileupload";
import { calculateHumanFileSize } from "../../../service/Filesize";
import IconCopy from "../svgs/IconCopy";
import IconCertified from "../svgs/IconCertified";
import { ServiceHotlineInfo } from "../view/InfoComponents";
import { Spinner } from "../view/Spinner";
import {
  buf2hex,
  emptyTemplate,
  headerTemplate,
  itemTemplate,
} from "../view/FileUpload";
import { PropsViews } from "../model/PropsInterfaces";

export default function BlockchainNotarisierungVerify(options: PropsViews) {
  const [verifyResult, setVerifyResult] =
    useState<MeinWKONotarisierungJsonResponseType>();
  const [hash256, setHash256] = useState<string>();
  const [txid, setTxid] = useState<string>();
  const [filename, setFilename] = useState<string>();
  const [fileInfo, setFileInfo] = useState<string>();
  const [loading, setLoading] = useState(false);
  var onRemove = () => {};

  const handleFileChange = (evt: any) => {
    var files = evt.files;
    for (var i = 0, f; (f = files[i]); i++) {
      setFilename(f.name);
      setFileInfo(
        new Date().toLocaleDateString() +
          " " +
          new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }) +
          " Uhr (" +
          calculateHumanFileSize(f.size, false, 2) +
          ")"
      );

      var reader = new FileReader();
      reader.onloadend = function (e: ProgressEvent<FileReader>) {
        if (e.target && e.target.readyState === FileReader.DONE) {
          if (
            window.crypto &&
            e.target.result &&
            e.target.result instanceof ArrayBuffer
          ) {
            var hash256: Promise<ArrayBuffer> = window.crypto.subtle.digest(
              "SHA-256",
              e.target.result
            );

            //CryptoOperation
            hash256.then(function (result) {
              setHash256(buf2hex(result));
            });
          }
        }
      };
      reader.readAsArrayBuffer(f);
    }
  };

  const verifyDocument = () => {
    const requestParameter: NotarisierungJsonRequestType = {
      hash256: hash256,
      txid: txid,
    };
    setLoading(true);
    blockchainService
      .verify(requestParameter)
      .then((prod) => {
        (document.getElementsByTagName("body")[0] as HTMLElement).classList.add(
          "proof"
        );
        let data: MeinWKONotarisierungJsonResponseType = prod.data;
        setVerifyResult({ ...verifyResult, ...data });
      })
      .finally(() => {
        window.dataLayer.push({
          event: "eservice-request",
          serviceName: "Blockchain – Dokumentprüfung durchgeführt",
        });
        setLoading(false);
      });
  };

  const itemTemplateVerify = (file: any, props: any) => {
    onRemove = props.onRemove;
    options.changeModalCallbackHandler!(resetVerifyView);
    return itemTemplate(
      file,
      "Noch nicht geprüft",
      options.showModalDialogHandler!
    );
  };

  const resetVerifyView = (fireCallback: boolean) => {
    if (fireCallback) {
      (
        document.getElementsByTagName("body")[0] as HTMLElement
      ).classList.remove("proof");
      setHash256("");
      setTxid(undefined);
      setFilename(undefined);
      setFileInfo(undefined);
      setVerifyResult(undefined);
      if (fireCallback && onRemove) {
        onRemove();
      }
    }
  };

  const handleHashChange = (evt: any) => {
    setHash256(evt.target.value);
  };

  const handleTxidChange = (evt: any) => {
    setTxid(evt.target.value);
  };

  const copyHashToClipboard = (evt: any) => {
    navigator.clipboard.writeText(hash256!);
  };

  const copyTxidToClipboard = (txid: string) => {
    navigator.clipboard.writeText(txid);
  };

  return (
    <>
      <section
        className="blockchain bg--white"
        data-gtm-section="Mein WKO - eService Dokumente prüfen"
      >
        <div className="container col-lg-7">
          <h1>Dokumente prüfen</h1>
          <div className="form">
            {!verifyResult && (
              <>
                <FileUpload
                  name="blockchain-edit"
                  customUpload={true}
                  itemTemplate={itemTemplateVerify}
                  emptyTemplate={emptyTemplate}
                  onSelect={handleFileChange}
                  headerTemplate={headerTemplate}
                />

                {hash256 && filename && (
                  <>
                    <div
                      className={
                        hash256 && filename
                          ? "element-input floating-label mb-5"
                          : "element-input floating-label"
                      }
                    >
                      <div className="element-text content form-group mb-3 col-7">
                        <div className="form-floating">
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            onChange={handleHashChange}
                            value={hash256}
                          ></input>
                          <label>Digitaler Fingerabdruck (optional)</label>
                        </div>
                      </div>
                      <div className="element-text content form-group mb-3 col-7">
                        <div className="form-floating">
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            onChange={handleTxidChange}
                            value={txid}
                          ></input>
                          <label>Transaktions ID (optional)</label>
                        </div>
                      </div>
                    </div>
                    {loading && <Spinner />}
                    {!loading && (
                      <button
                        className="btn btn-primary"
                        onClick={verifyDocument}
                      >
                        Dokument prüfen
                      </button>
                    )}
                  </>
                )}
              </>
            )}
            {verifyResult &&
              verifyResult.erfolgreich &&
              verifyResult.data &&
              verifyResult.data.length > 0 && (
                <>
                  <div className="file-meta">
                    <div className="file">
                      <span title={filename} className="file-title file-data">
                        {filename}
                      </span>
                      <span title={fileInfo} className="file-data">
                        Ausgewählt am: {fileInfo}
                      </span>
                    </div>
                    <div className="certificate d-flex align-items-center justify-content-end success">
                      <span className="title">
                        Die Echtheit des Dokuments kann nachgewiesen werden.
                      </span>
                      <IconCertified />
                    </div>
                  </div>
                  <div className="accordion">
                    <div className="accordion-item mb-5">
                      <p className="accordion-header" id="accordionHeadingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#accordionCollapseOne"
                          aria-expanded="false"
                          aria-controls="accordionCollapseOne"
                          data-gtm-element="content-accordion"
                          data-gtm-element-details="Lorem"
                        >
                          Details ansehen
                        </button>
                      </p>
                      <div
                        id="accordionCollapseOne"
                        className="accordion-collapse collapse bg--peach-secondary-30"
                        aria-labelledby="accordionHeadingOne"
                      >
                        <div className="accordion-body">
                          <p>
                            Der digitale Fingerabdruck (Hashwert) wurde in der
                            Blockchain gefunden und zum ersten Mal am{" "}
                            {new Intl.DateTimeFormat("de-AT", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            }).format(
                              new Date(
                                verifyResult?.data![0].blockTime
                                  ? verifyResult?.data![0].blockTime
                                  : ""
                              )
                            )}{" "}
                            um{" "}
                            {new Intl.DateTimeFormat("de-AT", {
                              hour: "2-digit",
                              minute: "2-digit",
                            }).format(
                              new Date(
                                verifyResult?.data![0].blockTime
                                  ? verifyResult?.data![0].blockTime
                                  : ""
                              )
                            )}{" "}
                            eingebracht.{" "}
                          </p>
                          <p className="text-truncate">
                            Der Fingerabdruck lautet:{" "}
                            <span className="fingerprint">{hash256}</span>
                            <button
                              className="copy"
                              onClick={copyHashToClipboard}
                            >
                              <IconCopy />
                            </button>
                          </p>
                          <p>
                            Damit ist bewiesen, dass das Dokument mit diesem
                            Fingerabdruck seit dem Einbringen in das
                            Datenzertifizierungsservice nicht verändert wurde.
                          </p>
                          <p>
                            Das Dokument wurde an den folgenden Tagen
                            eingebracht:
                          </p>
                          <ol>
                            {verifyResult?.data?.map((element) => (
                              <li key={element.txid}>
                                <p>
                                  Erstellt am:{" "}
                                  {new Intl.DateTimeFormat("de-AT", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  }).format(
                                    new Date(
                                      element.blockTime ? element.blockTime : ""
                                    )
                                  )}{" "}
                                  um{" "}
                                  {new Intl.DateTimeFormat("de-AT", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }).format(
                                    new Date(
                                      element.blockTime ? element.blockTime : ""
                                    )
                                  )}{" "}
                                  Uhr
                                </p>
                                <p className="text-truncate">
                                  Die Transaktions-ID lautet:{" "}
                                  <span className="fingerprint">
                                    {element.txid}
                                  </span>
                                  <button
                                    className="copy"
                                    onClick={() =>
                                      copyTxidToClipboard(element.txid!)
                                    }
                                  >
                                    <IconCopy />
                                  </button>
                                </p>
                              </li>
                            ))}
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-secondary"
                    onClick={() => resetVerifyView(true)}
                  >
                    Weiteres Dokument prüfen
                  </button>
                </>
              )}
            {verifyResult && !verifyResult.erfolgreich && (
              <>
                <div className="file-meta" style={{ border: "none" }}>
                  <div className="file">
                    <span title={filename} className="file-title file-data">
                      {filename}
                    </span>
                    <span title={fileInfo} className="file-data">
                      Ausgewählt am: {fileInfo}
                    </span>
                  </div>
                  <div className="certificate d-flex align-items-center justify-content-end failed">
                    <span className="title">
                      Die Echtheit des Dokuments kann NICHT nachgewiesen werden.
                    </span>
                  </div>
                </div>
                <button
                  className="btn btn-secondary"
                  onClick={() => resetVerifyView(true)}
                >
                  Weiteres Dokument prüfen
                </button>
              </>
            )}
          </div>
          <ServiceHotlineInfo />
        </div>
      </section>
    </>
  );
}
