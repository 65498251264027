import { calculateHumanFileSize } from "../../../service/Filesize";

export const getFileInfo = (f: any) => {
  return new Date().toLocaleDateString() +
    " " +
    new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    }) +
    " Uhr (" +
    calculateHumanFileSize(f.size, false, 2) +
    ")";
};
