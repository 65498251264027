export default function IconCertified() {
  return (
    <svg
      className="icon-certified"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 22.03"
    >
      <g
        id="icon-user-21_px"
        data-name="icon-user-21 px"
        transform="translate(0 0.5)"
      >
        <g
          id="Gruppe_2550"
          data-name="Gruppe 2550"
          transform="translate(-353 -438)"
        >
          <g
            id="streamlinehq-check-shield-interface-essential-20"
            transform="translate(353.7 438)"
          >
            <path
              id="Pfad_2869"
              data-name="Pfad 2869"
              d="M6.75,10.627l2.287,3.249a.98.98,0,0,0,1.593.048l7.32-9.265"
              transform="translate(-1.85 -1.01)"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              id="Pfad_2870"
              data-name="Pfad 2870"
              d="M2.9.749a1.4,1.4,0,0,0-1.4,1.4v8.4c0,4.492,6.752,9.491,9.047,10.976a1.379,1.379,0,0,0,1.506,0c2.3-1.485,9.047-6.484,9.047-10.976v-8.4a1.4,1.4,0,0,0-1.4-1.4Z"
              transform="translate(-1.5 -0.749)"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
