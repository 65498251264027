import { CSSProperties } from "react";
import IconHinweis from "../svgs/IconHinweis";

interface PropsHinweis {
  title?: string;
  message: string;
  style? : CSSProperties;
}

export function Hinweis(options: PropsHinweis) {
  return (
    <>
      <div
        className="alert alert-box alert-info border-0 rounded-0 bg--peach-secondary"
        role="alert"
        data-gtm-box="Hinweis"
      >
        <div className="alert-info-bulb">
          <IconHinweis style={options.style} />
          <span>Hinweis</span>
        </div>
        {options.title && <p className="title">{options.title}</p>}
        <p
          dangerouslySetInnerHTML={{
            __html: options.message || "",
          }}
        ></p>
      </div>
    </>
  );
}

export function NBWBHinweisBerechtigungen() {
  return (
    <Hinweis message="Wählen Sie einen oder mehrere Berechtigungen, um Ihre Auswahl weiter einzuschränken" />
  );
}

export function NBWBHinweisBestaetigung() {
  return (
    <Hinweis
      message="Die Bestätigung wird als PDF-Dokument erstellt und an Ihr WKO-Postfach
    zugestellt. Sie können es zusätzlich an eine beliebige E-Mail senden."
    />
  );
}

export function StammblattHinweisBestaetigung() {
  return (
    <Hinweis message="Die Bestätigung wird als PDF-Dokument erstellt und an Ihr WKO-Postfach zugestellt. Sie können es zusätzlich an eine beliebige E-Mail senden." />
  );
}
