import { PropsViews } from "../model/PropsInterfaces";
import { Hinweis } from "../view/Hinweise";
import IconMW from "../svgs/IconMW";
import { URL_BLOCKCHAIN_CREATE, URL_BLOCKCHAIN_VERIFY, getLink } from "../constants/urls";
import { Warnung } from "../view/Warnung";

export default function BlockchainNotarisierungHome(props: PropsViews) {
  return (
    <>
      <section className="blockchain bg--white" data-gtm-section="Mein WKO - eService Blockchain Datenzertifizierung">
        <div className="container col-lg-7">
          <div className="blockchain-header">
            <div className="headline">
              <div className="headline-bc">
                <h1>Blockchain Datenzertifizierung</h1>
                <h2 className="subtitle">
                  Innovationsservice: Daten einfach, sicher und kostenlos digital zertifizieren
                </h2>
              </div>
              <IconMW />
            </div>
            <a href={getLink(URL_BLOCKCHAIN_CREATE)} className="btn btn-primary">
              Gleich loslegen
            </a>
          </div>
          <div className="content">
            <h4>Nutzen der Blockchain-Datenzertifizierung</h4>
            <p>
              Mit dem Datenzertifizierungsservice der Wirtschaftskammern Österreichs kann man alle beliebigen Daten und
              Dateiformate, die am eigenen PC oder Server abgespeichert sind, mit Hilfe der Blockchain-Technologie
              zuerst zertifizieren und im Fall der Fälle jederzeit überprüfen lassen. <br></br>
              <br></br>
              Zum Nachweis der Urheberschaft, für Betriebs- oder Geschäftsgeheimnisse, die Dokumentation von
              Produktionsprozessen oder in Kooperationsprojekten (z. B. einem Bautagebuch) ist das Service mit
              Blockchain-Technologie der Wirtschaftskammern Österreichs eine wertvolle Hilfe. <br></br>
              <br></br>
              Der Nutzen ist immer der gleiche: Unternehmerinnen und Unternehmer können beweisen, welche Daten zu einem
              bestimmten Zeitpunkt vorgelegen sind und ob sie seither verändert wurden.<br></br>
              Der Datenbestand selbst bleibt immer im Unternehmen und nur eine Prüfsumme wird in der Blockchain
              gespeichert. Für die Speicherung und das Backup der Daten ist und bleibt das Unternehmen selbst
              verantwortlich.
            </p>
            <div className="hinweis">
              <Hinweis
                title="Dokumente zertifizieren"
                message={
                  "Lassen Sie Ihre Daten einfach, sicher und kostenlos <a href='" +
                  getLink(URL_BLOCKCHAIN_CREATE) +
                  "'> digital zertifizieren</a>. Damit können Sie später beweisen, ob die Daten seitdem verändert wurden."
                }
              />

              <Hinweis
                title="Dokumente prüfen"
                message={
                  "Werden Ihre Daten in Zweifel gezogen? Kein Problem - vor Gericht ist es jetzt möglich den digitalen Fingerabdruck der Datei zum Zeitpunkt der Erstellung vorzuweisen und damit den Zeitpunkt und den inhaltlichen Status des Vorliegens der Daten zu dokumentieren. <a href='" +
                  getLink(URL_BLOCKCHAIN_VERIFY) +
                  "'>Jetzt Dokument prüfen.</a>"
                }
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
