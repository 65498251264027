import { useState } from "react";
import {
  NotarisierungJsonRequestType,
  MeinWKONotarisierungJsonResponseType,
} from "../../../api";
import { TextAreaWithCounter } from "../../../form/TextAreaWithCounter";
import { blockchainService } from "../service/BlockchainService";
import { FileUpload } from "primereact/fileupload";
import { getFileInfo } from "../utils/FileUtils";
import { PropsViews } from "../model/PropsInterfaces";
import { ServiceHotlineInfo } from "../view/InfoComponents";
import { Spinner } from "../view/Spinner";
import {
  buf2hex,
  emptyTemplate,
  headerTemplate,
  itemTemplate,
} from "../view/FileUpload";
import IconCopy from "../svgs/IconCopy";

export default function BlockchainNotarisierungCreate(options: PropsViews) {
  const [createResult, setCreateResult] =
    useState<MeinWKONotarisierungJsonResponseType>();
  const [hash256, setHash256] = useState<string>();
  const [hash512, setHash512] = useState<string>();
  const [filename, setFilename] = useState<string>();
  const [remarks, setRemarks] = useState<string>();
  const [fileInfo, setFileInfo] = useState<string>();
  const [loading, setLoading] = useState(false);
  var onRemove = () => {};

  const handleFileChange = (evt: any) => {
    var files = evt.files;
    for (var i = 0, f; (f = files[i]); i++) {
      setFilename(f.name);
      setFileInfo(getFileInfo(f));

      var reader = new FileReader();
      reader.onloadend = function (e: ProgressEvent<FileReader>) {
        if (e.target && e.target.readyState === FileReader.DONE) {
          if (
            window.crypto &&
            e.target.result &&
            e.target.result instanceof ArrayBuffer
          ) {
            var hash256: Promise<ArrayBuffer> = window.crypto.subtle.digest(
              "SHA-256",
              e.target.result
            );
            var hash512: Promise<ArrayBuffer> = window.crypto.subtle.digest(
              "SHA-512",
              e.target.result
            );

            //CryptoOperation
            hash256.then(function (result) {
              setHash256(buf2hex(result));
            });
            hash512.then(function (result) {
              setHash512(buf2hex(result));
            });
          }
        }
      };
      reader.readAsArrayBuffer(f);
    }
  };

  const createNotarisierung = () => {
    const requestParameter: NotarisierungJsonRequestType = {
      filename: filename,
      hash256: hash256,
      hash512: hash512,
      remarks: remarks,
    };
    setLoading(true);
    blockchainService
      .create(requestParameter)
      .then((prod) => {
        (document.getElementsByTagName("body")[0] as HTMLElement).classList.add(
          "certify"
        );
        let data: MeinWKONotarisierungJsonResponseType = prod.data;
        (document.getElementsByTagName("body")[0] as HTMLElement).classList.add(
          "certify"
        );
        if (prod.data.erfolgreich) {
          window.dataLayer.push({
            event: "eservice-request",
            serviceName: "Blockchain – Dokumentzertifizierung durchgeführt",
          });
        }
        setCreateResult({ ...createResult, ...data });
      })
      .catch((e) => {
        debugger;
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openEDocumentLink = (evt: any) => {
    if (evt?.preventDefault) {
      evt.preventDefault();
    }
    if (createResult) {
      var win = window.open(createResult?.edocumentName, "_blank");
      if (win) {
        window.dataLayer.push({
          event: "eservice-request",
          serviceName: "Blockchain – Download Bestätigung erfolgt",
        });
        win.focus();
      }
    }
  };

  const itemTemplateCreate = (file: any, props: any) => {
    onRemove = props.onRemove;
    options.changeModalCallbackHandler!(resetCreateView);
    return itemTemplate(
      file,
      "Noch nicht zertifiziert",
      options.showModalDialogHandler!
    );
  };

  const resetCreateView = (fireCallback: boolean) => {
    if (fireCallback) {
      (
        document.getElementsByTagName("body")[0] as HTMLElement
      ).classList.remove("certify");
      setHash256(undefined);
      setHash512(undefined);
      setFilename(undefined);
      setRemarks(undefined);
      setCreateResult(undefined);
      if (onRemove) {
        onRemove();
      }
    }
  };

  const handleRemarksChange = (evt: any) => {
    setRemarks(evt.target.value);
  };

  return (
    <>
      <section
        className="blockchain bg--white"
        data-gtm-section="Mein WKO - eService Dokumente zertifizieren"
      >
        <div className="container col-lg-7">
          <h1>Dokumente zertifizieren</h1>
          <div className="form">
            {!createResult && (
              <>
                <FileUpload
                  name="blockchain-edit"
                  customUpload={true}
                  itemTemplate={itemTemplateCreate}
                  emptyTemplate={emptyTemplate}
                  onSelect={handleFileChange}
                  headerTemplate={headerTemplate}
                />
                {(hash256 || hash512) && (
                  <>
                    <div
                      className="element-textarea floating-label"
                      element-name="textarea9"
                      id="form-row-12"
                    >
                      <div className="form-floating form-group mb-3 col-7">
                        <TextAreaWithCounter
                          name="textbox"
                          maxLength={150}
                          value={remarks}
                          handleChange={handleRemarksChange}
                        ></TextAreaWithCounter>
                      </div>
                    </div>
                    {loading && <Spinner />}
                    {!loading && (
                      <button
                        className="btn btn-primary"
                        onClick={createNotarisierung}
                      >
                        Dokument zertifizieren
                      </button>
                    )}
                  </>
                )}
              </>
            )}
            {createResult && createResult.erfolgreich && (
              <>
                <div className="file-meta">
                  <div className="file">
                    <span title={filename} className="file-title file-data">
                      {filename}
                    </span>
                    <span title={fileInfo} className="file-data">
                      Ausgewählt am: {fileInfo}
                    </span>
                  </div>
                  <div className="certificate d-flex">
                    <div className="transaction">
                      <span title="Transaktions-ID" className="title">
                        Transaktions-ID
                      </span>
                      <span title={createResult.txid} className="file-data">
                        {createResult.txid}
                      </span>
                    </div>
                    <div className="btn btn-secondary">
                      <IconCopy />
                    </div>
                  </div>
                  <div className="certificate-meta">
                    <span className="date">
                      {new Intl.DateTimeFormat("de-AT", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(
                        new Date(
                          createResult.timeStamp ? createResult.timeStamp : ""
                        )
                      )}
                    </span>
                    <span> </span>
                    <span className="time">
                      {new Intl.DateTimeFormat("de-AT", {
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(
                        new Date(
                          createResult.timeStamp ? createResult.timeStamp : ""
                        )
                      )}{" "}
                      Uhr
                    </span>
                  </div>
                </div>
                <div
                  className="element-textarea floating-label"
                  element-name="textarea9"
                  id="form-row-12"
                >
                  <div className="form-floating form-group mb-3 col-7">
                    <textarea
                      disabled={true}
                      maxLength={150}
                      className="form-control"
                      placeholder="Leave a comment here"
                      id="textbox"
                      name="textbox"
                      style={{ height: "75px" }}
                      value={remarks}
                    ></textarea>
                    <label htmlFor="textbox" className="visually-hidden">
                      Anmerkungen (werden nicht in der Blockchain gespeichert)
                    </label>
                  </div>
                </div>
                <div className="action-button">
                  <button
                    className="btn btn-primary"
                    onClick={openEDocumentLink}
                  >
                    Bestätigung herunterladen
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => resetCreateView(true)}
                  >
                    Weiteres Dokument zertifizieren
                  </button>
                </div>
              </>
            )}
            {createResult && !createResult.erfolgreich && (
              <>
                <div className="form">
                  Das Dokument konnte leider nicht erfolgreich zertifiziert
                  werden. Bitte versuchen Sie es erneut.
                </div>
                <div className="action-button">
                  <button
                    className="btn btn-secondary"
                    onClick={() => resetCreateView(true)}
                  >
                    Weiteres Dokument zertifizieren
                  </button>
                </div>
              </>
            )}
          </div>
          <ServiceHotlineInfo />
        </div>
      </section>
    </>
  );
}
