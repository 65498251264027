import Modal from "react-bootstrap/Modal";

interface PropsFileUploadModal {
  modalId: string;
  show: boolean;
  resetViewHandler: (fireCallback: boolean) => any;
  onHide: () => void;
}

export function DeleteModal(props: PropsFileUploadModal) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      centered
      tabIndex={-1}
      aria-labelledby="deleteModalLabel"
    >
      <Modal.Header closeButton>
        <h1 className="modal-title fs-5">Dokument löschen</h1>
      </Modal.Header>
      <Modal.Body>Sind Sie sicher das Dokument zu löschen?</Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => props.resetViewHandler(false)}
        >
          Abbrechen
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => props.resetViewHandler(true)}
        >
          Ja, bitte löschen
        </button>
      </Modal.Footer>
    </Modal>
  );
}
