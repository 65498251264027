import { getFileInfo } from "../utils/FileUtils";
import IconDelete21 from "../svgs/IconDelete21";
import IconUpload from "../svgs/IconUpload";

const triggerFileChoose = (evt: any) => {
  if (evt?.preventDefault) {
    evt.preventDefault();
  }
  (
    document.getElementsByClassName("p-fileupload-choose")[0] as HTMLElement
  ).click();
};

export const buf2hex = (buffer: ArrayBuffer) => {
  return Array.prototype.map
    .call(new Uint8Array(buffer), function callback(currentValue) {
      return ("00" + currentValue.toString(16)).slice(-2);
    })
    .join("");
};

export const emptyTemplate = (options: any) => {
  return (
    <div
      className="element-file floating-label"
      element-name="file"
      id="form-row"
    >
      <div className="element-upload mb-4">
        <div className="icon icon-upload">
          <IconUpload />
        </div>
        <p>
          Ziehen Sie mit Drag &amp; Drop ein Dokument hierher oder verwenden Sie
          den Button um eine Datei auszuwählen.
        </p>
        <input
          type="file"
          id="file-upload"
          className="btn btn-primary mt-2 mb-4 form-control"
          onClick={triggerFileChoose}
        ></input>
      </div>
    </div>
  );
};

export const headerTemplate = (options: any) => {
  const { className, chooseButton } = options;
  return (
    <div className={className} style={{ display: "none" }}>
      {chooseButton}
    </div>
  );
};

export const itemTemplate = (file: any, message: string, showCaller: (show:boolean) => any ) => {
  return (
    <div className="file-meta">
      <div className="file">
        <span className="file-title">{file.name}</span>
        <br></br>
        <span className="file-data">Ausgewählt am: {getFileInfo(file)}</span>
      </div>
      <div className="certification">
        <p>{message}</p>
      </div>
      <div
        className="btn btn-secondary"
        onClick={() => showCaller(true)}
      >
        <IconDelete21 />
      </div>
    </div>
  );
};
