import { AxiosPromise } from "axios";
import { UserApi, BlockchainApi, NotarisierungJsonRequestType, MeinWKONotarisierungJsonResponseType, BasicUserInfoResponseType } from "../../../api";
import { BASE_PATH, BASE_PATH_UNAUTHORIZED } from "../../../constants/constants";
import { axiosInstance } from "../../../factory/AxiosFactory";

class BlockchainService {
  private static _instance: BlockchainService;

  private api = new BlockchainApi(undefined, BASE_PATH, axiosInstance);
  private apiUnauthorized = new BlockchainApi(undefined, BASE_PATH_UNAUTHORIZED, axiosInstance);
  private userApi = new UserApi(undefined, BASE_PATH, axiosInstance);

  private constructor() {}

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  public getInfo(): AxiosPromise<void> {
    return this.api.getBlockchainInfo();
  }

  public create(notarisierungJsonRequestType: NotarisierungJsonRequestType): AxiosPromise<MeinWKONotarisierungJsonResponseType> {
    return this.api.createNotarisierung(notarisierungJsonRequestType);
  }

  public verify(notarisierungJsonRequestType: NotarisierungJsonRequestType): AxiosPromise<MeinWKONotarisierungJsonResponseType> {
    return this.apiUnauthorized.verifyNotarisierung(notarisierungJsonRequestType);
  }

  public getUserInfo(): AxiosPromise<BasicUserInfoResponseType> {
    return this.userApi.getUserBasicInfo();
  }
}

export const blockchainService = BlockchainService.Instance;
