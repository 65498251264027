export function ServiceHotlineInfo() {
  return (
    <div className="content serviceline">
      <h4>Sie haben Fragen?</h4>
      <p>
        Unsere kostenlose WKO Serviceline unterstützt Sie gerne unter der Nummer
        0800 221 221 (Mo.-Fr. 8-20 Uhr, Sa. 8-12 Uhr) oder per E-Mail an{" "}
        <a href="mailto:benutzerkonto@wko.at">benutzerkonto@wko.at</a>.
      </p>
    </div>
  );
}
