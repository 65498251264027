import {
  FunctionComponent, useState,
} from "react";

interface Props {
  name: string;
  value?: any;
  maxLength: number;
  handleChange: (e: any) => void
}

export const TextAreaWithCounter: FunctionComponent<Props> = (props) => {
  const [charCount, setCharCount] = useState<number>(0);
  const [counterColor, setCounterColor] = useState<string>("black");
  
  const countCharacters = (evt:any) => {
      // Calculate the number of characters entered into the textarea
      let numOfEnteredChars = evt.target.value.length;
      // Calculate the number of characters left
      let counter = props.maxLength - numOfEnteredChars;
      // Display this number in the span tag
      setCharCount(counter);
      
      if (counter === 0) {
        setCounterColor("red");
      } else if (counter < 20) {
        setCounterColor("orange");
      } else {
        setCounterColor("black");
      }
  };

  return (
    <>
      <textarea maxLength={props.maxLength} className="form-control" placeholder="Anmerkungen" id="textbox" name={props.name} style={{height: '75px'}} onKeyUp={countCharacters} value={props.value} onChange={props.handleChange}></textarea>
      <label htmlFor="textbox">Anmerkungen (werden nicht in der Blockchain gespeichert)</label>
      <span id="char_count" style={{color: counterColor}}>{charCount}/{props.maxLength} Zeichen</span>
    </>
  );
};
