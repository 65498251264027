import * as React from "react";
import BlockchainNotarisierungController from "./controller/BlockchainNotarisierungController";
import { PropsViews } from "./model/PropsInterfaces";

export default function BlockchainNotarisierung(options : PropsViews) {
  return (
    <>
      <BlockchainNotarisierungController currentView={options.currentView} />
    </>
  );
}
