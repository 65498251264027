export const URL_HOME = "home";
export const URL_LOGOUT = "logout";
export const URL_IDP_LOGOUT = "idp_logout";
export const URL_STEWARD_LOGOUT = "steward_logout";
export const URL_NACHRICHTEN = "nachrichten";
export const URL_ALLE_ESERVICES = "alle_eservices";
export const URL_BLOCKCHAIN_CREATE = "blockchain_create";
export const URL_BLOCKCHAIN_VERIFY = "blockchain_verify";

export function getLink(key: string): string {
  if (window.location.hostname.indexOf("qss") >= 0) {
    switch (key) {
      case URL_HOME:
        return "https://blockchain.qss.wko.at";
      case URL_LOGOUT:
        return "https://blockchain.qss.wko.at/MeinWKOREST/b/logout/blockchain";
      case URL_IDP_LOGOUT:
        return "https://blueqss01.oe.wknet/stewardPortal/samlLogout?idplogout=true&redirectUrlForSteward=https://blockchain.qss.wko.at/MeinWKOREST/b/logout/blockchain";
      case URL_STEWARD_LOGOUT:
        return "https://blockchain.qss.wko.at/MeinWKOREST/b/logout/steward/blockchain";
      case URL_NACHRICHTEN:
        return "https://qss.wko.at/nachrichten";
      case URL_ALLE_ESERVICES:
        return "https://qss.wko.at/eservices";
      case URL_BLOCKCHAIN_CREATE:
        return "https://blockchain.qss.wko.at/create";
      case URL_BLOCKCHAIN_VERIFY:
        return "https://blockchain.qss.wko.at/verify";
    }
  } else {
    switch (key) {
      case URL_HOME:
        return "https://blockchain.wko.at";
      case URL_LOGOUT:
        return "https://blockchain.wko.at/MeinWKOREST/b/logout/blockchain";
      case URL_IDP_LOGOUT:
        return "https://eservice.jungewirtschaft.at/stewardPortal/samlLogout?idplogout=true&redirectUrlForSteward=https://blockchain.wko.at/MeinWKOREST/b/logout/blockchain";
      case URL_STEWARD_LOGOUT:
        return "https://blockchain.wko.at/MeinWKOREST/b/logout/steward/blockchain";
      case URL_NACHRICHTEN:
        return "https://wko.at/nachrichten";
      case URL_ALLE_ESERVICES:
        return "https://wko.at/eservices";
      case URL_BLOCKCHAIN_CREATE:
        return "https://blockchain.wko.at/create";
      case URL_BLOCKCHAIN_VERIFY:
        return "https://blockchain.wko.at/verify";
    }
  }
  return "";
}