import { addLocale, locale } from "primereact/api";
export const BASE_PATH = "/MeinWKOREST/b";
export const BASE_PATH_UNAUTHORIZED = "/MeinWKOREST/r";

export const ELEMENT_SCROLL_OFFSET = -100;

addLocale("de", {
  firstDayOfWeek: 1,
  dayNames: [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
  ],
  dayNamesShort: ["So", "Mon", "Die", "Mi", "Do", "Fr", "Sa"],
  dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
  monthNames: [
    "Jänner",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ],
  monthNamesShort: [
    "Jän.",
    "Feb.",
    "März",
    "Apr.",
    "Mai",
    "Juni",
    "Juli",
    "Aug.",
    "Sep.",
    "Okt.",
    "Nov.",
    "Dez.",
  ],
  today: "Heute",
  clear: "Löschen",
  dateFormat: "dd.mm.yy",
  weekHeader: "Wo",
});

locale("de");
