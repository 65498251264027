import { Link } from "react-router-dom";
import {
  DIALOG_STEP_HOME,
  DIALOG_STEP_CREATE,
  DIALOG_STEP_VERIFY,
} from "../constants/constants";

import { PropsViews } from "../model/PropsInterfaces";
import IconUser from "../svgs/IconUser";
import IconArrowRight from "../svgs/IconArrowRight";
import { URL_ALLE_ESERVICES, getLink } from "../constants/urls";
import AuthenticatedUserPopup from "./AuthenticatedUserPopup";
import { useEffect } from "react";

export default function BlockchainNotarisierungHeader(props: PropsViews) {
  const handleChange = (evt: any) => {
    if (props.changeViewHandler) {
      props.changeViewHandler(+evt.target.dataset.value);
    }
  };

  const isActive = (state: number) => {
    return props.currentView === state ? "active" : "";
  };

  const callLogin = (evt: any) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
      if (props.loginHandler) {
        props.loginHandler();
      }
    }
  };

  const initNavPanels = () => window.initNavPanels();
  const initOverflowNav = () => window.initOverflowNav();
  const mobileNav = () => window.mobileNav();

  useEffect(() => {
    initNavPanels();
    initOverflowNav();
    mobileNav();
  }, []);

  return (
    <>
      <header data-gtm-block="header">
        <nav className="navbar navbar-expand-lg" data-gtm-block="menu">
          <div className="container-fluid p-0">
            <div className="container d-flex">
              <div className="navbar-brand">
                <a href="/">
                  <img
                    src="/assets/logo.svg"
                    alt="Logo der Wirtschaftskammern Österreich"
                  />
                </a>
              </div>
              <div className="meta-nav">
                {props.userInfo && (
                  <button
                    className="btn btn-primary toggle-user"
                    id="user-trigger"
                    type="button"
                    title="Nachrichten anzeigen"
                    aria-label="Nachrichten anzeigen"
                    data-miu-link="http://wko.at/login?returnTo=xxxx"
                    data-bs-toggle="collapse"
                    data-bs-target="#User"
                    aria-expanded="false"
                    aria-controls="User"
                  >
                    <IconUser />
                    <span className="d-none d-lg-inline-block">
                      {props.userInfo.authenticatedUserName}
                    </span>
                  </button>
                )}
                {!props.userInfo && (
                  <button
                    className="btn btn-primary toggle-user"
                    id="user-trigger"
                    type="button"
                    title="Anmelden"
                    data-gtm-event="user-login-link"
                    onClick={callLogin}
                  >
                    <IconUser />
                    <span className="d-none d-lg-inline-block">Anmelden</span>
                  </button>
                )}
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
              </button>
              <AuthenticatedUserPopup userInfo={props.userInfo} />
            </div>
            <div
              className="collapse navbar-collapse border-top"
              id="navbarSupportedContent"
            >
              <div className="container d-flex">
                <ul
                  className="navbar-nav me-auto mb-2 mb-lg-0"
                  data-gtm-element="menu-main"
                >
                  <li key={"blockchain_menuitem_home"} className="nav-item">
                    <Link
                      to="/"
                      className={"nav-link " + isActive(DIALOG_STEP_HOME)}
                      onClick={handleChange}
                      data-value={DIALOG_STEP_HOME}
                    >
                      Blockchain Datenzertifizierung
                    </Link>
                  </li>
                  {props.userInfo && (
                    <li key={"blockchain_menuitem_create"} className="nav-item">
                      <Link
                        to="/create"
                        className={"nav-link " + isActive(DIALOG_STEP_CREATE)}
                        onClick={handleChange}
                        data-value={DIALOG_STEP_CREATE}
                      >
                        Dokumente zertifizieren
                      </Link>
                    </li>
                  )}
                  <li key={"blockchain_menuitem_verify"} className="nav-item">
                    <Link
                      to="/verify"
                      className={"nav-link " + isActive(DIALOG_STEP_VERIFY)}
                      onClick={handleChange}
                      data-value={DIALOG_STEP_VERIFY}
                    >
                      Dokumente prüfen
                    </Link>
                  </li>
                </ul>
                <ul className="navbar-nav meta-nav" data-gtm-element="menu-main">
                  <li className="nav-item">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={getLink(URL_ALLE_ESERVICES)}
                      className="nav-link"
                    >
                      Alle eServices&nbsp;
                      <IconArrowRight />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
