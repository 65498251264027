export default function IconCopy() {
  return (
    <svg
      className="icon icon-copy"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 21"
    >
      <g id="Gruppe_607" data-name="Gruppe 607" transform="translate(-989)">
        <g id="icon-duplicate-21" transform="translate(989)">
          <g
            id="streamlinehq-duplicate-1-interface-essential-20"
            transform="translate(1.705 1.705)"
          >
            <path
              id="Pfad_2807"
              data-name="Pfad 2807"
              d="M15.963,7.25h1.956a.711.711,0,0,1,.711.711v9.957a.711.711,0,0,1-.711.711H7.961a.711.711,0,0,1-.711-.711V15.963"
              transform="translate(-1.877 -1.877)"
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              id="Pfad_2808"
              data-name="Pfad 2808"
              d="M.75,1.461A.711.711,0,0,1,1.461.75h9.957a.711.711,0,0,1,.711.711v9.957a.711.711,0,0,1-.711.711H1.461a.711.711,0,0,1-.711-.711Z"
              fill="none"
              stroke="#000"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
