import { PropsFirmenSelect } from "../model/PropsInterfaces";

export function FirmenResultSelect(options: PropsFirmenSelect) {
  var items: JSX.Element[] = [];
  items.push(
    <option
      value={options.userInfo?.rollePersoenlich}
      key={options.userInfo?.rollePersoenlich}
    >
      Persönlich
    </option>
  );
  const roles: JSX.Element[] | undefined =
    options.userInfo?.rollenMitglied?.map((element) => (
      <option value={element.roleID} key={element.roleID}>
        {element.firmenbezeichnung}
      </option>
    ));
  roles?.forEach((role) => {
    items.push(role);
  });
  return (
    <>
      {(options.userInfo?.rollenMitglied?.length! > 0 && (
        <select
          className="form-select mb-4 mt-4"
          id="extra"
          aria-label="Wählen Sie Ihre Firma aus"
          value={options.userInfo?.authenticatedUserRoleId}
          onChange={options.onChangeHandler}
        >
          {items}
        </select>
      )) ||
        ""}
    </>
  );
}
