import { useEffect, useState } from "react";
import {
  DIALOG_STEP_HOME,
  DIALOG_STEP_CREATE,
  DIALOG_STEP_VERIFY,
} from "../constants/constants";
import { BasicUserInfoResponseType } from "../../../api";
import BlockchainNotarisierungHeader from "../component/Header";
import BlockchainNotarisierungFooter from "../component/Footer";
import BlockchainNotarisierungHome from "../component/Home";
import BlockchainNotarisierungVerify from "../component/Verify";
import BlockchainNotarisierungCreate from "../component/Create";
import { blockchainService } from "../service/BlockchainService";
import { PropsViews } from "../model/PropsInterfaces";
import { SessionTimer } from "../utils/SessionTimer";
import { cookieService } from "../service/CookieService";
import { LoadingScreen } from "../view/LoadingScreen";
import { DeleteModal } from "../view/Modal";
import Cookies from "universal-cookie";
import { URL_STEWARD_LOGOUT, getLink } from "../constants/urls";

const forwardToLogin = (options: PropsViews) => {
  let loginCallUrl = "/MeinWKOREST/b/login/blockchain";
  if (options.currentView === DIALOG_STEP_CREATE) {
    loginCallUrl += ";subpath=create";
  } else if (options.currentView === DIALOG_STEP_VERIFY) {
    loginCallUrl += ";subpath=verify";
  }
  window.location.href = loginCallUrl;
}

export default function BlockchainNotarisierungController(options: PropsViews) {
  const [initializing, setInitializing] = useState(true);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState<BasicUserInfoResponseType>();
  const [shouldLogin, setShouldLogin] = useState(false);
  const [deleteModalCreateShow, setDeleteModalCreateShow] = useState(false);
  const [deleteModalVerifyShow, setDeleteModalVerifyShow] = useState(false);
  var modalCloseCallback = (fireCallback: boolean) => {};
  

  useEffect(() => {
    if (!userInfo && (initializing || shouldLogin)) {
      const cookies = new Cookies(null, { path: "" });
      let loginState = cookies.get("loginState");
      if (shouldLogin) {
        cookies.set("loginState", "firstTry");
        forwardToLogin(options);
      } else if (loginState === "retry") {
        cookies.set("loginState", "retried");
        forwardToLogin(options);
      } else {
        setInitializing(false);
        setLoading(true);
        cookieService
          .getCookies()
          .then((res) => {
            if (res.data && res.data !== "noCookie") {
              blockchainService
                .getUserInfo()
                .then((res) => {
                  let data: BasicUserInfoResponseType = res.data;
                  cookies.remove("loginState");
                  cookies.remove("loginError");
                  setUserInfo({ ...data });
                })
                .catch(() => {
                  if (!cookies.get("loginError")) {
                    cookies.set("loginError", "noUserData");
                    setShouldLogin(true);
                  }
                })
                .finally(() => {
                  setLoading(false);
                });
            } else {
              if (loginState === "firstTry") {
                cookies.set("loginState","retry");
                window.location.href = getLink(URL_STEWARD_LOGOUT);
              } else {
                if (options.currentView === DIALOG_STEP_CREATE) {
                  setShouldLogin(true);
                }
                setLoading(false);
              }
            }
          })
          .catch(() => {
            if (options.currentView === DIALOG_STEP_CREATE) {
              options.currentView = DIALOG_STEP_HOME;
            }
            setLoading(false);
          });
      }
    }
  }, [userInfo, shouldLogin, initializing, options]);

  const handleViewChange = (newView: number) => {
    (document.getElementsByTagName("body")[0] as HTMLElement).classList.remove(
      "start"
    );
    (document.getElementsByTagName("body")[0] as HTMLElement).classList.remove(
      "certify"
    );
    (document.getElementsByTagName("body")[0] as HTMLElement).classList.remove(
      "proof"
    );
    if (newView === DIALOG_STEP_HOME) {
      (document.getElementsByTagName("body")[0] as HTMLElement).classList.add(
        "start"
      );
    }
  };

  const callLogin = () => {
    if (!userInfo) {
      if (shouldLogin) {
        // back button from wkis clicked
        forwardToLogin(options);
      }
      setShouldLogin(true);
    }
  };

  const changeModalCallbackHandler = (callback: any) => {
    modalCloseCallback = callback;
  };

  const callModalCallback = (fireCallback: boolean) => {
    setDeleteModalCreateShow(false);
    setDeleteModalVerifyShow(false);
    modalCloseCallback(fireCallback);
  };

  useEffect(() => {
    if (userInfo) {
      const sessionTimer = new SessionTimer(userInfo);
      sessionTimer.startCounter();
    }
  }, [userInfo]);

  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && (
        <>
          <BlockchainNotarisierungHeader
            currentView={options.currentView}
            changeViewHandler={handleViewChange}
            userInfo={userInfo}
            loginHandler={callLogin}
          />

          <main data-gtm-block="main">
            {options.currentView === DIALOG_STEP_HOME && (
              <BlockchainNotarisierungHome
                currentView={options.currentView}
                userInfo={userInfo}
                loginHandler={callLogin}
              />
            )}

            {userInfo && options.currentView === DIALOG_STEP_CREATE && (
              <BlockchainNotarisierungCreate
                currentView={options.currentView}
                userInfo={userInfo}
                changeViewHandler={handleViewChange}
                changeModalCallbackHandler={changeModalCallbackHandler}
                showModalDialogHandler={setDeleteModalCreateShow}
              />
            )}

            {options.currentView === DIALOG_STEP_VERIFY && (
              <>
                <BlockchainNotarisierungVerify
                  changeModalCallbackHandler={changeModalCallbackHandler}
                  showModalDialogHandler={setDeleteModalVerifyShow}
                />
              </>
            )}
          </main>
          <BlockchainNotarisierungFooter changeViewHandler={handleViewChange} />

          {userInfo && options.currentView === DIALOG_STEP_CREATE && (
            <DeleteModal
              modalId="deleteModalCreate"
              show={deleteModalCreateShow}
              resetViewHandler={callModalCallback}
              onHide={() => callModalCallback(false)}
            />
          )}
          {options.currentView === DIALOG_STEP_VERIFY && (
            <DeleteModal
              modalId="deleteModalVerify"
              show={deleteModalVerifyShow}
              resetViewHandler={callModalCallback}
              onHide={() => callModalCallback(false)}
            />
          )}
        </>
      )}
    </>
  );
}